import { ImgTypes } from "./constants";
const cloudName = "jeanscentre";
const cloudinaryUrl = `https://res.cloudinary.com/${cloudName}/image/fetch`;
// Use different optimisation options for images through cloudinary URL. Must use this one for less problems with formats (ex. GIFs)
export const imageUrl = (src, imgType = "") => {
    if (src) {
        switch (imgType) {
            case ImgTypes.promotionalTag:
                return `${cloudinaryUrl}/w_120,h_120,f_auto,q_auto:good/${src}`;
            case ImgTypes.pdpMainImage:
                return `${cloudinaryUrl}/w_700,h_1050,c_pad,b_white,f_auto,q_auto:good/${src}`;
            case ImgTypes.paymentMethodIcon:
                return `${cloudinaryUrl}/w_80,h_40,c_pad,b_white,f_auto,q_auto:good/${src}`;
            case ImgTypes.paymentMethodIconGiftcard:
                return `${cloudinaryUrl}/w_60,h_40,c_pad,b_white,f_auto,q_auto:good/${src}`;
            // case ImgTypes.navigationImage:
            //     return `${cloudinaryUrl}/w_300,h_300,f_auto,q_auto:good/${src}`
            case ImgTypes.socialIcon:
                return `${cloudinaryUrl}/w_30,h_30,f_auto,q_auto:good/${src}`;
            case ImgTypes.footerLogo:
                return `${cloudinaryUrl}/w_auto,h_35,f_auto,q_auto:good/${src}`;
            case ImgTypes.cartLineItemImage:
                return `${cloudinaryUrl}/w_150,h_225,f_auto,q_auto:good/${src}`;
            case ImgTypes.listerTileImage:
                return `${cloudinaryUrl}/c_scale,w_auto,h_500,f_auto,q_auto:good/${src}`;
            case ImgTypes.listerTileImageThumb:
                return `${cloudinaryUrl}/c_scale,w_auto,h_500,f_auto,q_10/${src}`;
            // case ImgTypes.brandLogo:
            //   return `https://res.cloudinary.com/${ cloudName }/image/fetch/w_120,h_auto,f_auto,q_auto:good/${ src }`;
            default:
                return `${cloudinaryUrl}/w_auto,f_auto,q_auto:good/${src}`;
        }
    }
};
