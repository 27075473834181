import { useWishlist } from "@/lib/store/wishlist";
import { useI18n } from "@jog/react-components";
import { gtmAdpageEmail, sessionController } from "@jog/shared";
import classNames from "classnames";
import { ArrowText, Button, Input, LogoLoader } from "components";
import { getSession, signIn } from "next-auth/react";
import { useCallback, useState } from "react";
const LoginForm = ({ emailForCheck, isCheckout, toggleModal, goToStepOne, onLogin, }) => {
    const { $t } = useI18n();
    const [password, setPassword] = useState("");
    const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
    const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(true);
    const [isLoginIng, setLoginStatus] = useState(false);
    const mergeWishlist = useWishlist.use.mergeWishlist();
    const myWishlist = useWishlist.use.myWishlist();
    const onPasswordFieldChange = (event) => {
        setPassword(event.target.value);
        setIsLoginBtnDisabled(!event.target.value || event.target.value.length <= 4);
    };
    const handleLoginUser = useCallback(async (event) => {
        try {
            event.preventDefault();
            setLoginStatus(true);
            setIsPasswordInvalid(false);
            const res = await signIn("credentials", {
                redirect: false,
                email: String(emailForCheck).toLowerCase(),
                password: password,
            }).then(async (res) => {
                if (res.ok) {
                    const session = await getSession();
                    sessionController().set(session);
                }
                return res;
            });
            gtmAdpageEmail(emailForCheck.toLowerCase());
            setLoginStatus(false);
            if (res.ok) {
                await (onLogin === null || onLogin === void 0 ? void 0 : onLogin());
                const wishlistId = localStorage.getItem("wishlistId");
                if (wishlistId) {
                    await mergeWishlist();
                }
                else {
                    await myWishlist();
                }
            }
            if (res.error) {
                setIsPasswordInvalid(true);
            }
        }
        catch {
            setIsPasswordInvalid(false);
            setLoginStatus(false);
        }
    }, [emailForCheck, mergeWishlist, myWishlist, onLogin, password]);
    return (<div className="text-base">
            {isLoginIng && <LogoLoader />}
            {!isCheckout && <h1 className="mb-6.25">{$t("Log in / Register")}</h1>}

            <form className="mb-7.5">
                <div className="mb-7.5 flex flex-col items-center lg:flex-row">
                    <div className="flex w-full basis-full items-center lg:basis-2/12">
                        <label htmlFor="username">{$t("E-mail address")} *</label>
                    </div>
                    <div className={classNames("w-full basis-full", {
            "lg:basis-7/12": !isCheckout,
            "lg:basis-10/12": isCheckout,
        })}>
                        <div className="flex items-center bg-gray-bg">
                            <Input className="!h-11.5 w-full border border-gray-medium py-2.75 px-3 !text-left" id="username" name="username" type="email" value={emailForCheck} disabled={true}/>
                        </div>
                    </div>
                </div>
                <div className={classNames("mb-7.5 flex flex-col items-center lg:flex-row")}>
                    <div className="flex w-full basis-full items-center lg:basis-2/12">
                        <label htmlFor="password">{$t("Password")} *</label>
                    </div>
                    <div className={classNames("w-full basis-full", {
            "lg:basis-7/12": !isCheckout,
            "lg:basis-10/12": isCheckout,
        })}>
                        <div className="flex items-center">
                            <Input className="!h-11.5 w-full border border-gray-medium py-2.75 px-3 !text-left" id="password" name="password" type="password" onInput={onPasswordFieldChange} defaultValue={password}/>
                        </div>

                        {isPasswordInvalid && <div className="password-error">{$t("Invalid password")}</div>}
                    </div>
                </div>
                <div className="flex">
                    <div className={classNames("flex w-full basis-full lg:justify-end", isCheckout ? "justify-end lg:basis-full" : "justify-end lg:basis-9/12")}>
                        <Button className="hover:bg-auto" bgColor="#000" color="#fff" fontWeight="600" disabled={isLoginBtnDisabled} onClick={handleLoginUser}>
                            {$t("next one")}
                        </Button>
                    </div>
                </div>
            </form>

            <div className="mb-2.75" onClick={() => toggleModal()}>
                <ArrowText>{$t("Forgot your password?")}</ArrowText>
            </div>
            <div>
                <span onClick={goToStepOne} className="inline-block">
                    <ArrowText className="mb-2.5">{$t("Log in with a different account")}</ArrowText>
                </span>
            </div>
        </div>);
};
export default LoginForm;
