import { Button } from "@/components/Button/Button";
import { WrongCountryErrorMessage, checkCountry } from "@/util/helperFunctions";
import { ReCaptchaDisclaimer, useI18n, useValidateEmail } from "@jog/react-components";
import { dispatchInputChange, forgotPassword } from "@jog/shared";
import { AlertBox, Container, Input, LogoLoader, Modal, WhiteBox } from "components";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useStores } from "stores";
import LoginForm from "./LoginForm";
import LoginResetPassword from "./LoginResetPassword";
const LoginContentComponent = () => {
    const { userStore } = useStores();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isUserEmailNotRegistered, setIsUserEmailNotRegistered] = useState(false);
    const [email, setEmail] = useState("");
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [availableError, setAvailableError] = useState(null);
    const [isResetPasswordEmailNotRegistered, setIsResetPasswordEmailNotRegistered] = useState(false);
    const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(null);
    const [isResetPasswordEmailSent, setIsResetPasswordEmailSent] = useState(false);
    const { $t } = useI18n();
    const router = useRouter();
    const getReCaptchaToken = useCallback(async (action) => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }
        return await executeRecaptcha(action);
    }, [executeRecaptcha]);
    const goToStepOne = async () => {
        setShowLogin(false);
        setEmail("");
        // Trigger check if Enter key is pressed
        setTimeout(() => {
            var _a;
            (_a = emailElement.current) === null || _a === void 0 ? void 0 : _a.focus();
        }, 100);
    };
    const checkIfUserEmailIsRegistered = useCallback(async () => {
        const token = await getReCaptchaToken("validateEmail");
        try {
            const { available: isNotRegistered, message, country } = await userStore.checkIfUserExists(email, token);
            if (message) {
                setAvailableError({ message, country: "" });
                return;
            }
            if (!isNotRegistered && !checkCountry(country)) {
                setAvailableError({ country, message: "" });
                return;
            }
            setAvailableError(null);
            setIsUserEmailNotRegistered(isNotRegistered);
            const lowerCaseEmail = String(email).toLowerCase();
            if (isNotRegistered) {
                userStore.unregisteredEmail = lowerCaseEmail;
                router.push(`/signup`);
            }
            else {
                setShowLogin(true);
                setTimeout(() => {
                    dispatchInputChange('input[name="username"]', lowerCaseEmail);
                }, 0);
                // Focus on Password field
                setTimeout(() => {
                    const passwordInput = document.getElementsByName("password");
                    if (passwordInput[0])
                        passwordInput[0].focus();
                }, 100);
            }
        }
        catch (e) {
            setAvailableError({ message: e.message, country: "" });
        }
    }, [email, getReCaptchaToken, router, userStore]);
    const [captchaFail, setCaptchaFail] = useState("");
    const checkEmailForPasswordReset = useCallback(async (email) => {
        const token = await getReCaptchaToken("validateEmail");
        setCaptchaFail("");
        const { available: isNotRegistered, message } = await userStore.checkIfUserExists(email, token);
        if (message) {
            setCaptchaFail(message);
            return;
        }
        if (isNotRegistered) {
            setIsResetPasswordEmailNotRegistered(true);
        }
        else {
            const response = await forgotPassword(email);
            if (response) {
                setIsResetPasswordEmailSent(true);
            }
        }
    }, [getReCaptchaToken, userStore]);
    const listenToEnter = useCallback((event) => {
        if (event.key === "Enter" && !isEmailInvalid) {
            checkIfUserEmailIsRegistered();
        }
    }, [checkIfUserEmailIsRegistered, isEmailInvalid]);
    useEffect(() => {
        var _a;
        const url = new URL(window.location.href);
        const paramResetPasswordSuccess = url.searchParams.get("resetPasswordSuccess");
        (_a = emailElement.current) === null || _a === void 0 ? void 0 : _a.focus();
        if (paramResetPasswordSuccess !== null) {
            setIsResetPasswordSuccess(paramResetPasswordSuccess);
        }
        // Collect param values if coming from the special password reset email link (example.com/login?email=abc@gmail.com&rp=true)
        const paramEmail = url.searchParams.get("email");
        const paramResetPassword = url.searchParams.get("rp");
        if (paramEmail && paramResetPassword) {
            setEmail(paramEmail);
            setShowModal(true);
        }
    }, [listenToEnter]);
    const { validateEmail } = useValidateEmail();
    const getEmailValue = useCallback((e) => {
        setEmail(e.target.value);
        setIsEmailInvalid(!validateEmail(e.target.value));
        setIsBtnDisabled(!validateEmail(e.target.value));
    }, [validateEmail]);
    const toggleModal = (show) => {
        setShowModal(!showModal);
        if (!show) {
            setIsResetPasswordEmailNotRegistered(false);
            setIsResetPasswordEmailSent(false);
        }
    };
    const emailElement = React.useRef(null);
    const session = useSession();
    useEffect(() => {
        if (session.status === "authenticated") {
            router.push(`/my-account`);
        }
    }, [router, session.data, session.status]);
    if (session.status === "authenticated") {
        return (<div className="text-sm">
                <Container className="mb-14">
                    <LogoLoader />
                </Container>
            </div>);
    }
    return (<div className="text-sm">
            <Container className="mb-14">
                {userStore.isLoading && <LogoLoader />}
                {isResetPasswordSuccess === "false" ? (<AlertBox type="error" text={$t("The link you have used is not (more) valid")}/>) : (<></>)}
                {isResetPasswordSuccess === "true" ? (<AlertBox text={$t("Lucky you can now log in with your new password")}/>) : (<></>)}
                <WhiteBox>
                    {!isUserEmailNotRegistered && !showLogin && (<>
                            <h1 className="my-5 text-lg">{$t("Log in / Register")}</h1>
                            <p className="mb-5.5">
                                {$t(`Enter your e-mail address. We will then check whether you already have an account.`)}
                            </p>
                            <div className="mt-5.5 flex flex-wrap items-center">
                                <div className="w-full basis-full lg:basis-3/12">
                                    <label htmlFor="emailOne" className="mt-2.5">
                                        {$t("E-mail address")} *
                                    </label>
                                </div>

                                <div className="w-full basis-full lg:basis-7/12">
                                    {availableError ? (availableError.message ? (<p>{availableError.message}</p>) : availableError.country ? (<WrongCountryErrorMessage country={availableError.country}/>) : (<></>)) : (<></>)}
                                    <div className="flex items-center">
                                        <Input className="!h-11.5 w-full border border-gray-medium py-2.75 px-3 !text-left" ref={emailElement} id="emailOne" onChange={getEmailValue} onKeyUp={listenToEnter} type="email"/>
                                    </div>

                                    {isEmailInvalid && (<div className="mt-1.25 text-right text-13">{$t("Invalid email address")}</div>)}
                                </div>
                                <div className="mt-6 flex basis-full justify-end lg:basis-10/12">
                                    <Button className="hover:bg-auto" bgColor="#000" color="#fff" fontWeight="600" onClick={checkIfUserEmailIsRegistered} disabled={isBtnDisabled}>
                                        {$t("Log in / Register")}
                                    </Button>
                                </div>
                            </div>
                        </>)}

                    {!isUserEmailNotRegistered && showLogin && (<LoginForm emailForCheck={email} isCheckout={false} toggleModal={() => toggleModal(true)} goToStepOne={goToStepOne}/>)}
                    <ReCaptchaDisclaimer className="text-black"/>
                </WhiteBox>
            </Container>

            {showModal && (<Modal headerTitle={$t("Reset password")} hideModal={() => toggleModal(false)} width="50%">
                    {isResetPasswordEmailNotRegistered && (<AlertBox type="warning" text={$t("WE DO NOT HAVE AN ACCOUNT FOR THIS EMAIL ADDRESS, THEREFOR CREATE A NEW ACCOUNT")}/>)}
                    {captchaFail && <AlertBox type="warning" text={$t(captchaFail)}/>}
                    {isResetPasswordEmailSent && (<AlertBox text={$t("WE HAVE SENT YOU AND E-MAIL STATING HOW YOU CAN RESET YOUR PASSWORD")}/>)}
                    {!isResetPasswordEmailNotRegistered && !isResetPasswordEmailSent && (<LoginResetPassword checkEmailForPasswordReset={checkEmailForPasswordReset} email={email}/>)}
                </Modal>)}
        </div>);
};
export const LoginContent = observer(LoginContentComponent);
