import { Button } from "@/components/Button/Button";
import { FormikInputField, Link, useI18n } from "@jog/react-components";
import { confirmForgotPassword, passwordValidation } from "@jog/shared";
import { LogoLoader } from "components";
import { Form, Formik } from "formik";
import { useRouter } from "next/router";
import React, { useCallback, useState } from "react";
const ResetPasswordForm = () => {
    const [isBtnSubmitting, setIsBtnSubmitting] = useState(false);
    const { $t } = useI18n();
    const router = useRouter();
    const handleSubmit = useCallback(async (values) => {
        setIsBtnSubmitting(true);
        const url = new URL(window.location.href);
        const email = url.searchParams.get("email");
        const confirmationCode = url.searchParams.get("code");
        const result = await confirmForgotPassword(email, confirmationCode, values.newPassword);
        setIsBtnSubmitting(false);
        await router.push({ pathname: "/login", query: { resetPasswordSuccess: !!result } });
    }, [router]);
    return (<div className="w-full px-1.75 text-sm">
            <h1 className="my-5 font-primary text-3.5xl font-normal uppercase text-black">{$t("Password")}</h1>
            {isBtnSubmitting && <LogoLoader />}
            <Formik initialValues={{ newPassword: "", confirmNewPassword: "" }} validate={(values) => {
            const errors = {};
            if (!passwordValidation(values.newPassword)) {
                errors.newPassword = $t("Minimum 6 characters");
            }
            if (!passwordValidation(values.confirmNewPassword)) {
                errors.confirmNewPassword = $t("Minimum 6 characters");
            }
            else if (values.newPassword !== values.confirmNewPassword) {
                errors.confirmNewPassword = $t("Password does not match");
            }
            return errors;
        }} onSubmit={handleSubmit}>
                {() => (<Form>
                        <div className="mt-7.5">
                            <div className="flex">
                                <div className="w-full basis-full md:basis-8/12">
                                    <div className="-mx-2 mb-5 flex flex-wrap">
                                        <FormikInputField id="newPassword" fieldName="newPassword" type="password" label={$t("New password *")}/>
                                    </div>

                                    <div className="-mx-2 mb-5 flex flex-wrap">
                                        <FormikInputField id="confirmNewPassword" fieldName="confirmNewPassword" type="password" label={$t("Confirm new Password *")}/>
                                    </div>

                                    <div className="flex">
                                        <div className="mb-5 w-full basis-full md:basis-8/12">
                                            <Link href="/">
                                                <Button className="mr-4 !bg-gray-shuttle text-white hover:bg-auto">
                                                    {$t("Cancel")}
                                                </Button>
                                            </Link>
                                            <Button className="!bg-black text-white hover:bg-auto" type="submit" disabled={isBtnSubmitting}>
                                                {$t("Update")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>)}
            </Formik>
        </div>);
};
export default React.memo(ResetPasswordForm);
