import { Price } from "@/components/Price/Price";
import { ImgTypes } from "@/util/constants";
import { imageUrl } from "@/util/imgCloudinaryUrls";
import { Link, NextImage, useI18n } from "@jog/react-components";
import { JC_FRIEND_SKU } from "@jog/shared";
import classNames from "classnames";
import { kebabCase } from "lodash-es";
const PropertyItem = ({ propertyName, propertyValue, }) => (<div className="py-2.5 text-center font-primary text-sm font-medium lg:py-0 lg:text-base">
        <span className="block text-center font-normal capitalize">{propertyName}</span>
        {propertyValue}
    </div>);
const LineItems = ({ order }) => {
    const { $t } = useI18n();
    const locale = process.env.NEXT_PUBLIC_LOCALE;
    return (<>
            {order.lineItems.map((item) => {
            const localizedCategoryAsUrl = item.categoryAsUrlAllLocales[locale];
            const localizedName = item.name[locale] || item.name.en;
            const localizedProductSlug = item.productSlugAllLocales[locale];
            const linkItem = `/${localizedCategoryAsUrl}/${kebabCase(localizedName)}/${kebabCase(localizedProductSlug)}`;
            return (<div key={item.id} className={classNames("w-full border-b border-gray-lightSand py-5 px-0 lg:w-1/2", item.sku === JC_FRIEND_SKU ? "hidden" : "")}>
                        <div className="flex gap-x-4">
                            <div className="basis-3/12 self-center lg:basis-2/12">
                                <div className="relative flex">
                                    <Link href={linkItem} title="" className="w-full text-center">
                                        <NextImage blur={false} src={imageUrl(item.image, ImgTypes.cartLineItemImage)} className="img-fluid w-full" height={225} width={150} alt="Cart item image"/>
                                    </Link>
                                </div>
                            </div>

                            <div className="flex basis-9/12 lg:basis-10/12">
                                <div className="flex w-full flex-wrap">
                                    <div className="basis-full">
                                        <Link href={linkItem} title="" className="info-title">
                                            <h6 className="text-base capitalize">{localizedName}</h6>
                                        </Link>
                                        <div className="mb-2.5 w-full self-center pl-2 lg:mb-0 lg:w-auto lg:pl-0">
                                            {item.promotionDescription && item.promotionDescription[locale] && (<div className="font-primary text-base italic text-red-main">
                                                    {item.promotionDescription[locale]}
                                                </div>)}
                                        </div>
                                    </div>
                                    <div className="basis-full lg:basis-6/12">
                                        <div>
                                            <div className="flex justify-between">
                                                {item.length && (<PropertyItem propertyName={$t("Length")} propertyValue={item.length}/>)}
                                                <PropertyItem propertyName={$t("Size")} propertyValue={item.size}/>
                                                <PropertyItem propertyName={$t("Quantity")} propertyValue={item.quantity}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex basis-full lg:basis-6/12">
                                        <div className="mt-2.5 flex w-full flex-wrap justify-start lg:mt-0 lg:ml-0 lg:justify-end">
                                            <div className="flex w-full flex-nowrap justify-end">
                                                {item.prices.discountPrice ? (<div className="flex flex-col items-end gap-y-2">
                                                        <div>
                                                            <Price fontSize="16px" isOldPrice={true} price={item.prices.firstPriceInTheCart.centAmount} className="!font-normal"/>
                                                        </div>
                                                        <div>
                                                            <Price className="!text-red-main" fontSize="18px" price={item.prices.discountPrice.centAmount}/>
                                                        </div>
                                                    </div>) : (<Price fontSize="18px" price={item.prices.totalPrice.centAmount}/>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>);
        })}
        </>);
};
export default LineItems;
